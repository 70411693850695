import React, { useEffect } from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import { loginRequest } from "@/authConfig";

export const MSALAuthentication = ({ children }) => {
  const { login, error } = useMsalAuthentication(
    InteractionType.Silent,
    loginRequest
  );

  useEffect(() => {
    if (error) {
      login(InteractionType.Redirect, loginRequest);
    }
  }, [error, login]);

  return (
    <>
      <AuthenticatedTemplate>
        <>{children}</>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div>Unauthenticated</div>
      </UnauthenticatedTemplate>
    </>
  );
};

export default MSALAuthentication;
