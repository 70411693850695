import CASaygonTextTtf from "../fonts/CASaygonText-Regular.ttf";
import CASaygonTextEot from "../fonts/CASaygonText-Regular.eot";

const FontRegular = {
  fontFamily: "CASaygonText",
  fontStyle: "normal",
  fontWeight: 400,
  src: `local('CASaygonText'), url(${CASaygonTextEot}), url(${CASaygonTextTtf}) format('ttf'), url(../fonts/CASaygonText-Regular.eot) format('eot')`,
};

export default [FontRegular];
