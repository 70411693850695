import { Chip } from "@mui/material";
import React from "react";

type Props = {
  status: string;
};

const StatusChip = ({ status }: Props) => {
  const text = () => {
    switch (status) {
      case "waitingforupload":
        return "Waiting for upload";
      case "processing":
        return "Processing";
      case "completed":
        return "Completed";
      case "failed":
        return "Failed";
      case "pendingverification":
        return "Pending Verification";
      default:
        return "Verified";
    }
  };

  const colour = () => {
    switch (status) {
      case "waitingforupload":
        return "error";
      case "processing":
        return "warning";
      case "completed":
        return "success";
      case "failed":
        return "error";
      case "pendingverification":
        return "warning";
      default:
        return "info";
    }
  };

  return (
    <Chip variant="outlined" label={`Status: ${text()}`} color={colour()} />
  );
};

export default StatusChip;
