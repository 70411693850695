import { IMenuItem, Select } from "@adl/smart-ui-library";
import { FormControl } from "@mui/material";
import React, { useEffect } from "react";

import { fileCategories } from "../../constants";

type Props = {
  text: string;
  onUpdate: (text: string) => void;
};

const EditableCategory = ({ text, onUpdate }: Props) => {
  const [listItems, setListItems] = React.useState<IMenuItem[]>();

  useEffect(() => {
    const items: IMenuItem[] = [];
    fileCategories.map((item) => {
      items.push({
        id: item.id,
        name: item.name,
        icon: null,
        value: item.value,
        tooltip: "",
        dataTestId: "",
      });
    });
    setListItems(items);
  }, []);

  if (!listItems) return null;

  return (
    <FormControl>
      <Select
        activeTabIndex={text}
        onChange={(e) => {
          onUpdate(e as string);
        }}
        list={listItems}
      ></Select>
    </FormControl>
  );
};

export default EditableCategory;
