import { PageHeader, RenderIf, SmartSearchLogo } from "@adl/smart-ui-library";
import { useMsal } from "@azure/msal-react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import NotificationDropdown from "@/components/Notifications/Notifications";
import { useAppDispatch } from "@/hooks";
import { setSelectedTasks } from "@/redux/tasksSlice";

type Props = {
  data: any;
  document: any;
  navigateToHome: () => void;
  navigateToCases: () => void;
};

const SelectedDocumentPageHeader = ({
  data,
  document,
  navigateToHome,
  navigateToCases,
}: Props) => {
  const { instance } = useMsal();
  const appDispatch = useAppDispatch();
  return (
    <PageHeader
      logo={<SmartSearchLogo variant="default" handleClick={navigateToHome} />}
      navigation={
        <>
          <NotificationDropdown />
          <Button onClick={() => appDispatch(setSelectedTasks([]))}>
            Tasks
          </Button>
          <Button onClick={() => instance.logout()}>Logout</Button>
        </>
      }
      subHeader={
        <>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <IconButton onClick={navigateToHome}>
              <HomeOutlinedIcon />
            </IconButton>
            <Button variant="text" onClick={navigateToCases}>
              {data.P_number}
            </Button>
            <Typography color="text.primary">
              {document.fileNameOriginal || "Original Document Name"}
            </Typography>
          </Breadcrumbs>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <RenderIf isTrue={true}>
              <Button
                variant="outlined"
                data-test="document-open-button"
                disableElevation
                startIcon={<DownloadOutlinedIcon />}
                onClick={() => {
                  console.log("Download");
                }}
                size="small"
              >
                Download
              </Button>
            </RenderIf>

            <Button
              size="small"
              variant="contained"
              data-test="document-open-button"
              disableElevation
              startIcon={<PreviewOutlinedIcon />}
              onClick={() => {
                console.log("Preview");
                //previewDocument(document.metadata_spo_item_id);
              }}
            >
              Preview
            </Button>
            <Button
              variant="contained"
              size="small"
              data-test="document-open-button"
              disableElevation
              startIcon={<VisibilityOutlinedIcon />}
              onClick={() => {
                console.log("Open");
                //toggleDocModal(document.metadata_spo_item_weburi)
              }}
            >
              Open
            </Button>
          </Box>
        </>
      }
    >
      <Typography variant="h1">Knowledge Vault</Typography>
    </PageHeader>
  );
};

export default SelectedDocumentPageHeader;
