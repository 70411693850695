import { createSlice } from "@reduxjs/toolkit";

interface IAuthState {
  instance: any;
  account: any;
  isLoading: boolean;
}

export const NAME = "auth";

const initialState: IAuthState = {
  instance: null,
  account: { name: "Joe Bloggs" },
  isLoading: true,
};

const reducers = {
  setToken: (state: IAuthState, { payload: data }) => {
    state.instance = data.instance;
    state.account = data.account;
    state.isLoading = false;
  },
  setInstance: (state: IAuthState, { payload: data }) => {
    state.instance = data;
    state.isLoading = false;
  },
  setAccount: (state: IAuthState, { payload: data }) => {
    state.account = data;
    state.isLoading = false;
  },
};

export const authSlice = createSlice({
  name: NAME,
  initialState,
  reducers,
});

export const selectAuthState = (state) => state[NAME];
export const selectAccount = (state) => state[NAME].account;
export const selectAuthIsLoading = (state) => state[NAME].isLoading;

export const { setToken, setInstance, setAccount } = authSlice.actions;

export default authSlice.reducer;
