import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useAppSelector, useAppDispatch } from "@/hooks";
import {
  selectCases,
  fetchAllCases,
  setSelectedCase,
  selectIsLoading,
  selectPagination,
  CasesPayload,
  ITableFilter,
} from "@/redux/casesSlice";
import * as Styled from "./DashboardContent.styles";
import { columns } from "./Table.data";
import Table from "../Table";

const DashboardContent = () => {
  const data = useAppSelector(selectCases);
  const pageination = useAppSelector(selectPagination);
  const isLoading = useAppSelector(selectIsLoading);
  const appDispatch = useAppDispatch();
  const [params, setParams] = useState<CasesPayload>({
    skip: 0,
    top: 20,
    sort: "",
    include: "*",
  });
  console.log("pageination", pageination);
  const onSelectCase = (data: any) => {
    appDispatch(setSelectedCase(data));
  };

  const onChangeFilters = (filter: ITableFilter[]) => {
    const args = { ...params };
    if (filter.length > 0) {
      args["filter"] = { items: filter };
    } else {
      delete args["filter"];
    }
    setParams(args);
  };

  const onSortChange = (sort: { sort: string }) => {
    const args = { ...params };
    if (sort.sort) {
      args.sort = sort.sort;
    } else {
      args.sort = "";
    }
    setParams(args);
  };

  const onPaginationChange = (pagination: number) => {
    const args = { ...params };
    args.skip = pagination;
    setParams(args);
  };

  useEffect(() => {
    appDispatch(fetchAllCases(params));
  }, [params, appDispatch]);

  return (
    <Styled.DashboardContent>
      <Typography variant="h5">My Cases</Typography>
      <Table
        height={800}
        data={data}
        columns={columns}
        totalPages={pageination.total}
        pageSize={pageination.top}
        pageNumber={pageination.skip / pageination.top}
        onSelectRow={onSelectCase}
        isLoading={isLoading}
        onFilterChange={onChangeFilters}
        onSortChange={onSortChange}
        onPaginationChange={onPaginationChange}
      />
    </Styled.DashboardContent>
  );
};

export default DashboardContent;
