import { Theme } from "@mui/material";

let themeInstance: Theme | null = null;

export const setTheme = (theme: Theme): void => {
  themeInstance = theme;
};

export const getTheme = (): Theme => {
  if (!themeInstance) {
    throw new Error("Theme has not been set. Ensure setTheme is called.");
  }
  return themeInstance;
};
