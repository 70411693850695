import axios from "axios";

import { loginRequest, msalInstance } from "./authConfig";

const axiosInstance = axios.create({
  baseURL: `${import.meta.env.VITE_APP_API}`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      const accounts = msalInstance.getAllAccounts();

      if (accounts.length === 0) {
        msalInstance.acquireTokenRedirect(loginRequest);
      } else {
        const silentRequest = {
          ...loginRequest,
          account: accounts[0],
        };

        const tokenResponse = await msalInstance.acquireTokenSilent(
          silentRequest
        );
        config.headers["Authorization"] = `Bearer ${tokenResponse.idToken}`;
      }
    } catch (error) {
      console.error("Error acquiring token silently:", error);
      throw error;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
