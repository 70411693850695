export const PADDING = 2;

export const randomHexColors = [
  "#FF5733",
  "#29A0B3",
  "#F984E5",
  "#00A35E",
  "#9C27B0",
  "#FFC107",
  "#795548",
  "#3F51B5",
  "#E91E63",
  "#4CAF50",
  "#FF5722",
  "#2196F3",
  "#673AB7",
  "#FF9800",
  "#607D8B",
  "#CDDC39",
  "#009688",
  "#FFEB3B",
  "#9E9E9E",
  "#8BC34A",
];

export const fileCategories = [
  { name: "Proposal", value: "proposal", id: "1" },
  { name: "Project Material", value: "project_material", id: "2" },
  { name: "Qual", value: "qual", id: "3" },
  { name: "Contract", value: "contract", id: "4" },
  { name: "Other", value: "other", id: "5" },
];
export const confidentialityLevels = [
  {
    name: "Private",
    value: "private",
    id: "1",
  },
  {
    name: "Public",
    value: "public",
    id: "2",
  },
  {
    name: "Team",
    value: "team",
    id: "3",
  },
];
