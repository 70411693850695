import { Show } from "@adl/smart-ui-library";
import { Box, useTheme } from "@mui/material";
import React from "react";
import { v4 } from "uuid";
import { getStatusColour } from "../../utils";
import { ICaseDocSatus } from "../../redux/casesSlice";

export interface ITimeLineData {
  id: number;
  renderer: React.ReactNode;
  status: ICaseDocSatus;
}

type Props = {
  direction?: "horizontal" | "vertical";
  data: ITimeLineData[];
  limit?: number;
};

export const TimeLine = ({
  direction = "horizontal",
  data,
  limit = 10,
}: Props) => {
  console.log(data);
  return (
    <Box
      sx={{
        width: "100%",
        height: direction === "horizontal" ? "80px" : "auto",
        display: "flex",
        justifyContent:
          direction === "horizontal" ? "space-between" : "flex-start",
        flexDirection: direction === "horizontal" ? "row" : "column",
        gap: "10px",
        alignItems: direction === "horizontal" ? "center" : "flex-start",
        marginBottom: "10px",
      }}
    >
      {data.map((item, index) => {
        if (index < limit) {
          return (
            <React.Fragment key={v4()}>
              <TimeLineNode
                label={item.renderer}
                colour={getStatusColour(item.status)}
                direction={direction}
              />
              {index + 1 !== limit && <TimeLineDivide direction={direction} />}
            </React.Fragment>
          );
        }
      })}
    </Box>
  );
};

export default TimeLine;

type NodeProps = {
  colour: string;
  direction?: string;
  subLabel?: string | null;
  label: React.ReactNode | string;
};

const TimeLineNode = ({
  label,
  colour,
  direction = "horizontal",
}: NodeProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: direction === "horizontal" ? "column" : "row",
        gap: "10px",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "20px",
          height: "20px",
          backgroundColor: colour,
          borderRadius: "5px",
        }}
      ></Box>
      <Show>
        <Show.When isTrue={direction === "horizontal"}>
          <Box sx={{ position: "absolute", top: "30px" }}>{label}</Box>
        </Show.When>
        <Show.Else>{label}</Show.Else>
      </Show>
    </Box>
  );
};

type DivideProps = { direction?: string };

const TimeLineDivide = ({ direction = "horizontal" }: DivideProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: direction === "horizontal" ? "4px" : "20px",
        width: direction === "horizontal" ? "100%" : "12px",
        backgroundColor:
          direction === "horizontal" ? theme.palette.grey[400] : "transparent",
        borderRadius: direction === "horizontal" ? "5px" : "0px",
        borderRight:
          direction === "horizontal"
            ? "none"
            : `4px solid ${theme.palette.grey[400]}`,
      }}
    ></Box>
  );
};
