import { createSlice } from "@reduxjs/toolkit";

interface IState {
  isHighContrast: boolean;
  headerDrawerOpen: boolean;
  sideDrawerOpen: boolean;
}

export const NAME = "app";

const initialState = {};

const reducers = {};

export const appSlice = createSlice({
  name: NAME,
  initialState,
  reducers,
});

export default appSlice.reducer;
