import { Breadcrumbs, Button, IconButton, Typography } from "@mui/material";
import React from "react";
import DefaultTemplate from "@/templates/DefaultTemplate/DefaultTemplate";
import {
  PageHeader,
  SmartSearchLogo,
  PageContent,
} from "@adl/smart-ui-library";
import { useAppSelector, useAppDispatch } from "@/hooks";
import { selectSelectedCases, setSelectedCase } from "@/redux/casesSlice";
import SelectedCaseContent from "@/components/SelectedCaseContent/SelectedCaseContent";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { useMsal } from "@azure/msal-react";
import NotificationDropdown from "@/components/Notifications/Notifications";
import { setSelectedTasks } from "@/redux/tasksSlice";

const SelectedCase = () => {
  const { instance } = useMsal();
  const appDispatch = useAppDispatch();
  const data = useAppSelector(selectSelectedCases);

  const navigateToHome = () => {
    appDispatch(setSelectedCase(null));
  };

  return (
    <DefaultTemplate paddingTop={200}>
      <PageHeader
        logo={
          <SmartSearchLogo variant="default" handleClick={navigateToHome} />
        }
        navigation={
          <>
            <NotificationDropdown />
            <Button onClick={() => appDispatch(setSelectedTasks([]))}>
              Tasks
            </Button>
            <Button onClick={() => instance.logout()}>Logout</Button>
          </>
        }
        subHeader={
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <IconButton onClick={navigateToHome}>
              <HomeOutlinedIcon />
            </IconButton>
            <Typography color="text.primary">{data.P_number}</Typography>
          </Breadcrumbs>
        }
      >
        <Typography variant="h1">Knowledge Vault</Typography>
      </PageHeader>
      <PageContent>
        <SelectedCaseContent />
      </PageContent>
    </DefaultTemplate>
  );
};

export default SelectedCase;
