import { Backdrop, Box, Button, useTheme } from "@mui/material";
import React, { useEffect, useReducer } from "react";
import { PageContent, LoadingSpinner } from "@adl/smart-ui-library";
import { useAppSelector, useAppDispatch } from "@/hooks";
import {
  selectSelectedCases,
  setSelectedCase,
  selectSelectedDocument,
  setSelectedDocument,
} from "@/redux/casesSlice";
import DefaultTemplate from "@/templates/DefaultTemplate/DefaultTemplate";
import DocumentProfileContent from "@/components/DocumentProfileContent";
import SaveChangesModal from "./SaveChangesModal";
import { reducer } from "./SelectedDocumentPage.reducer";
import SelectedDocumentPageHeader from "./SelectedDocumentPageHeader";
import DiscardChangesModal from "./DiscardChangesModal";
import * as Styled from "./SelectedDocumentPage.styles";

const SelectedDocument = () => {
  const theme = useTheme();
  const appDispatch = useAppDispatch();
  const data = useAppSelector(selectSelectedCases);
  const document = useAppSelector(selectSelectedDocument);
  const [isLoading, setIsLoading] = React.useState(false);

  const [state, dispatch] = useReducer(reducer, {
    summary: document?.summary,
    fileName: document?.fileNameOriginal,
    category: document?.category || "",
    confidentialityLevel: document?.confidentiality || "",
    isEdited: false,
  });

  const [saveModalOpen, setSaveModalOpen] = React.useState<boolean>(false);
  const [continueModalOpen, setContinueModalOpen] =
    React.useState<boolean>(false);
  const [continueAction, setContinueAction] = React.useState<any>(null);

  const navigateToHome = () => {
    if (state.isEdited) {
      setContinueModalOpen(true);
      setContinueAction(() => () => setSelectedCase(null));
    } else {
      appDispatch(setSelectedCase(null));
      appDispatch(setSelectedDocument(null));
    }
  };

  const navigateToCases = () => {
    if (state.isEdited) {
      setContinueModalOpen(true);
      setContinueAction(() => () => setSelectedDocument(null));
    } else {
      appDispatch(setSelectedDocument(null));
    }
  };

  const onSave = () => {
    setSaveModalOpen(false);
    dispatch({ type: "SET_IS_EDITED", payload: false });
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);

      console.log("Saved");
    }, 2500);
  };

  const onContinue = () => {
    setContinueModalOpen(false);
    if (continueAction) {
      dispatch({ type: "SET_IS_EDITED", payload: false });
      appDispatch(continueAction());
    }
  };

  const onClose = () => {
    setSaveModalOpen(false);
    setContinueModalOpen(false);
  };

  const onReset = () => {
    dispatch({ type: "SET_SUMMARY", payload: document?.summary });
    dispatch({ type: "SET_FILE_NAME", payload: document?.fileNameOriginal });
    dispatch({ type: "SET_CATEGORY", payload: document?.category });
    dispatch({
      type: "SET_CONFIFENTIALITY_LEVEL",
      payload: document?.confidentiality,
    });
    dispatch({ type: "SET_IS_EDITED", payload: false });
  };

  useEffect(() => {
    if (
      state.summary === "" &&
      state.fileName === "" &&
      state.category === "" &&
      state.confidentialityLevel === ""
    ) {
      dispatch({ type: "SET_IS_EDITED", payload: false });
      return;
    }

    const isEdited =
      state.summary !== document?.summary ||
      state.fileName !== document?.fileNameOriginal ||
      state.category !== document?.category ||
      state.confidentialityLevel !== document?.confidentiality;

    if (state.isEdited !== isEdited) {
      dispatch({ type: "SET_IS_EDITED", payload: isEdited });
    }
  }, [state]);

  return (
    <DefaultTemplate paddingTop={200}>
      <SelectedDocumentPageHeader
        data={data}
        document={document}
        navigateToCases={navigateToCases}
        navigateToHome={navigateToHome}
      />
      <PageContent>
        <Styled.Wrapper>
          <Styled.InputContainer>
            <DocumentProfileContent dispatch={dispatch} state={state} />
          </Styled.InputContainer>

          <Styled.ResetSaveButtonContainer>
            <Button
              variant="text"
              disableElevation
              disabled={!state.isEdited}
              onClick={onReset}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              disableElevation
              disabled={!state.isEdited}
              onClick={() => setSaveModalOpen(true)}
            >
              Save
            </Button>
          </Styled.ResetSaveButtonContainer>
        </Styled.Wrapper>
      </PageContent>

      <SaveChangesModal
        open={Boolean(saveModalOpen)}
        onClose={onClose}
        onSave={onSave}
      />
      <DiscardChangesModal
        open={Boolean(continueModalOpen)}
        onClose={onClose}
        onContinue={onContinue}
      />
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={isLoading}
      >
        <LoadingSpinner />
      </Backdrop>
    </DefaultTemplate>
  );
};

export default SelectedDocument;
