import React, { useEffect, useState } from "react";
import Table from "@/components/Table";
import {
  RenderActions,
  RenderDateTime,
  RenderUploadStatus,
} from "@/components/Table/Table.renderers";
import { useAppSelector } from "@/hooks";
import { selectedSelectedCasePagination } from "@/redux/casesSlice";

type Props = {
  data: any;
  onUpdatePage: (pageination: any) => void;
  onSelectedRow: (row: any) => void;
};

const FileTable = ({ data, onUpdatePage, onSelectedRow }: Props) => {
  const [parsedData, setParsedData] = useState<any[]>([]);
  const pageination = useAppSelector(selectedSelectedCasePagination);

  useEffect(() => {
    const parsed = data?.map((item: any) => {
      return {
        ...item,
        actions: item.fileStatus,
        fileNameOriginal: item.fileNameOriginal || "N/A",
      };
    });
    setParsedData(parsed);
  }, [data]);

  const columns = [
    //{ field: "caseDetailId", headerName: "Created" },
    // { field: "category", headerName: "Time", width: 60 },
    // { field: "confidentiality", headerName: "Activity", flex: 1 },
    {
      field: "fileNameOriginal",
      headerName: "File Name",
      flex: 1,
      sortable: false,
      filterable: false,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params) => <RenderDateTime value={params.value} />,
    },
    // { field: "documentId", headerName: "Created" },
    // { field: "fileNameBlob", headerName: "Time", width: 60 },

    //  { field: "fileSASUrl", headerName: "Name" },
    {
      field: "fileStatus",
      headerName: "Status",
      width: 220,
      sortable: false,
      filterable: false,
      renderCell: (params) => <RenderUploadStatus value={params.value} />,
    },
    //  { field: "isDeleted", headerName: "Time", width: 60 },
    //   { field: "isValidated", headerName: "Activity", flex: 1 },
    // { field: "updatedAt", headerName: "Name" },
    {
      field: "actions",
      headerName: "",
      width: 150,
      renderCell: (params) => <RenderActions value={params.value} />,
      sortable: false,
      filterable: false,
    },
  ];

  const onPaginationChange = (pagination: number) => {
    onUpdatePage(pagination);
  };

  return (
    <Table
      height={600}
      data={parsedData}
      columns={columns}
      totalPages={pageination.total}
      pageSize={pageination.top}
      pageNumber={pageination.skip / pageination.top}
      onSelectRow={onSelectedRow}
      isLoading={false}
      onFilterChange={() => null}
      onSortChange={() => null}
      onPaginationChange={onPaginationChange}
    />
  );
};

export default FileTable;
