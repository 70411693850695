import React, { useEffect } from "react";
import {
  Alert,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  fetchCaseDetailsById,
  fetchCaseActivitiesById,
  selectIsError,
  selectIsLoading,
  selectSelectedCaseDetails,
  setSelectedDocument,
  selectSelectedCases,
  selectSelectedCaseStatus,
  deleteCaseDocument,
} from "@/redux/casesSlice";
import { RenderIf, Show } from "@adl/smart-ui-library";
import { useAppSelector, useAppDispatch } from "@/hooks";
import LoadingScreen from "../LoadingScreen";
import FileUploader from "../FileUploader";
import Timeline from "../TimeLine";
import Activities from "../Activities";
import Content from "./Content";
import { config } from "./timeline.config";

import * as Styled from "./SelectedCaseContent.styles";
import { setSelectedCaseActivites } from "../../redux/casesSlice";

const SelectedCaseContent = () => {
  const data = useAppSelector(selectSelectedCases);
  const isLoading = useAppSelector(selectIsLoading);
  const hasError = useAppSelector(selectIsError);
  const details = useAppSelector(selectSelectedCaseDetails);
  const status = useAppSelector(selectSelectedCaseStatus);
  const appDispatch = useAppDispatch();
  const [restiction, setRestriction] = React.useState<string | null>("public");
  const [skip, setSkip] = React.useState(0);
  const [view, setView] = React.useState("cards");

  useEffect(() => {
    appDispatch(
      fetchCaseDetailsById({
        deliveryEngagementId: data.DeliveryEngagementId,
        email: "kamba.marina@adlittle.com",
        top: 10,
        skip: skip,
      })
    );
  }, [skip, appDispatch]);

  useEffect(() => {
    if (details && details?.length > 0) {
      appDispatch(
        fetchCaseActivitiesById({
          caseDetailId: data.DeliveryEngagementId,
          top: 10,
          skip: skip,
        })
      );
    }
  }, [details, appDispatch]);

  const handleRestrictionChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    setRestriction(newAlignment);
  };

  const uploadComplete = () => {
    setTimeout(() => {
      appDispatch(
        fetchCaseDetailsById({
          deliveryEngagementId: data.DeliveryEngagementId,
          email: "kamba.marina@adlittle.com",
          top: 10,
          skip: skip,
        })
      );
    }, 2000);
  };

  const handleDelete = (documentId) => {
    appDispatch(
      deleteCaseDocument({
        deliveryEngagementId: data.DeliveryEngagementId,
        documentId: documentId,
      })
    );
  };
  return (
    <Styled.CaseDetailsContent id="case-details-content">
      <Styled.CaseDetailsContentLeft id="case-details-content-left">
        <Typography variant="h5">{data.ShortName}</Typography>

        <ToggleButtonGroup
          value={restiction}
          exclusive
          onChange={handleRestrictionChange}
          aria-label="text alignment"
        >
          <ToggleButton
            value="public"
            aria-label="left aligned"
            size="small"
            sx={{ textTransform: "capitalize" }}
          >
            <GroupOutlinedIcon />
            Full Access
          </ToggleButton>
          <ToggleButton
            value="restricted"
            aria-label="centered"
            sx={{ textTransform: "capitalize" }}
          >
            <LockOutlinedIcon />
            Restricted to Project Team
          </ToggleButton>
        </ToggleButtonGroup>
        <Typography variant="body1">
          {data.description ||
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vestibulum nunc ac dolor tincidunt faucibus. Integer quis rutrum augue, at imperdiet odio. Quisque luctus nulla velit, eu ullamcorper augue blandit at. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec pulvinar, ipsum vel mollis volutpat, purus ligula cursus eros, tempor suscipit ipsum risus sit amet leo."}
        </Typography>
        <RenderIf isTrue={Boolean(status)}>
          <Timeline data={config(status)} limit={4} />
        </RenderIf>
        <Show>
          <Show.When isTrue={isLoading}>
            <LoadingScreen />
          </Show.When>

          <Show.Else>
            <Show>
              <Show.When isTrue={hasError}>
                <Alert severity="error">Error fetching data</Alert>
              </Show.When>
              <Show.Else>
                <Content
                  details={details}
                  update={(skip) => {
                    setSkip(skip);
                  }}
                  onSelectFile={(data) => {
                    console.log(data);
                    appDispatch(setSelectedCaseActivites(null));
                    appDispatch(setSelectedDocument(data));
                  }}
                  toggleView={() =>
                    setView(view === "cards" ? "table" : "cards")
                  }
                  view={view}
                  onDeleteFile={(data) => handleDelete(data)}
                />
              </Show.Else>
            </Show>
          </Show.Else>
        </Show>
      </Styled.CaseDetailsContentLeft>
      <Styled.CaseDetailsContentRight id="case-details-content-right">
        <FileUploader
          id={data.DeliveryEngagementId}
          email={"kamba.marina@adlittle.com"}
          uploadComplete={uploadComplete}
        />
        <Activities />
      </Styled.CaseDetailsContentRight>
    </Styled.CaseDetailsContent>
  );
};

export default SelectedCaseContent;
