export interface IState {
  summary: string | null | undefined;
  fileName: string | null | undefined;
  category: string;
  confidentialityLevel: string;
  isEdited: boolean;
}

export const initState: IState = {
  summary: "",
  fileName: "",
  category: "",
  confidentialityLevel: "",
  isEdited: false,
};

export const SET_SUMMARY = "SET_SUMMARY";
export const SET_FILE_NAME = "SET_FILE_NAME";
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_CONFIFENTIALITY_LEVEL = "SET_CONFIFENTIALITY_LEVEL";
export const SET_IS_EDITED = "SET_IS_EDITED";

export const reducer = (state, action) => {
  switch (action.type) {
    case SET_SUMMARY: {
      return {
        ...state,
        summary: action.payload,
      };
    }
    case SET_FILE_NAME: {
      return {
        ...state,
        fileName: action.payload,
      };
    }
    case SET_CATEGORY: {
      return {
        ...state,
        category: action.payload,
      };
    }
    case SET_CONFIFENTIALITY_LEVEL: {
      return {
        ...state,
        confidentialityLevel: action.payload,
      };
    }
    case SET_IS_EDITED: {
      return {
        ...state,
        isEdited: action.payload,
      };
    }
  }

  return state;
};
