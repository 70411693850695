import { createSlice } from "@reduxjs/toolkit";

interface ITasksState {
  selectedTasks: boolean;
  updatedTasks: boolean;
}

export const NAME = "tasks";

const initialState : ITasksState = {
  selectedTasks: false,
  updatedTasks: false,
};


const reducers = {
  setSelectedTasks: (state: ITasksState, { payload }) => {
    state.selectedTasks = payload;
  },
  setUpdatedTasks : (state: ITasksState, { payload }) => {
    state.updatedTasks = payload;
  },

};
export const tasksSlice = createSlice({
  name: NAME,
  initialState,
  reducers,
});

export const selectTasks = (state) => state[NAME].selectedTasks;
export const updateTasks = (state) => state[NAME].updatedTasks;
export const { setSelectedTasks } = tasksSlice.actions;
export const { setUpdatedTasks } = tasksSlice.actions;

export default tasksSlice.reducer;
