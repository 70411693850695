import { Button, Typography } from "@mui/material";
import React from "react";
import DefaultTemplate from "@/templates/DefaultTemplate/DefaultTemplate";
import {
  PageHeader,
  SmartSearchLogo,
  PageContent,
} from "@adl/smart-ui-library";
import DashboardContent from "@/components/DashboardContent";
import { useMsal } from "@azure/msal-react";
import NotificationDropdown from "@/components/Notifications/Notifications";
import { useAppDispatch } from "@/hooks";
import { setSelectedTasks } from "@/redux/tasksSlice";

const Dashboard = () => {
  const { instance } = useMsal();
  const appDispatch = useAppDispatch();
  return (
    <DefaultTemplate>
      <PageHeader
        logo={<SmartSearchLogo variant="default" handleClick={() => null} />}
        navigation={
          <>
            <NotificationDropdown />
            <Button onClick={() => appDispatch(setSelectedTasks([]))}>
              Tasks
            </Button>
            <Button onClick={() => instance.logout()}>Logout</Button>
          </>
        }
      >
        <Typography variant="h1">Knowledge Vault</Typography>
      </PageHeader>
      <PageContent>
        <DashboardContent />
      </PageContent>
    </DefaultTemplate>
  );
};

export default Dashboard;
