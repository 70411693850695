import { PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: `${import.meta.env.VITE_APP_AAD_CLIENT_ID}`,
    authority: `${import.meta.env.VITE_APP_AAD_AUTHORITY}`,
    redirectUri: `${import.meta.env.VITE_APP_AAD_REDIRECT}`,
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

export const loginRequest = {
  scopes: [`${import.meta.env.VITE_APP_AAD_CLIENT_ID}/Vault.Access`],
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

export const msalInstance = new PublicClientApplication(msalConfig);
