import React from "react";
import { Show, Pagination } from "@adl/smart-ui-library";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import FileCard from "../FileCard/FileCard";
import FileTable from "./FileTable";
import { useAppSelector, useAppDispatch } from "@/hooks";
import { selectedSelectedCasePagination } from "@/redux/casesSlice";
import { v4 } from "uuid";

const Content = ({
  details,
  update,
  toggleView,
  view,
  onSelectFile,
  onDeleteFile,
}: {
  details: any;
  update: (value: any) => void;
  toggleView: () => void;
  view: string;
  onSelectFile: (data: any) => void;
}) => {
  const pagination = useAppSelector(selectedSelectedCasePagination);

  const reveasedDetails = details ? [...details].reverse() : null;
  return (
    <Show>
      <Show.When isTrue={reveasedDetails?.length === 0}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingTop: "200px",
          }}
        >
          <Typography variant="body1">
            No files available for this case
          </Typography>
        </Box>
      </Show.When>
      <Show.Else>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={view}
            onChange={(event) => toggleView()}
          >
            <FormControlLabel value="cards" control={<Radio />} label="Cards" />
            <FormControlLabel value="table" control={<Radio />} label="Table" />
          </RadioGroup>
        </FormControl>
        <Show>
          <Show.When isTrue={view === "cards"}>
            {reveasedDetails?.map((item) => {
              return (
                <FileCard key={v4()} data={item} onSelect={onSelectFile} onDeleteFile={onDeleteFile} />
              );
            })}
            <Box
              sx={{
                width: "100%",
                textAlign: "center",

                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination
                totalResults={pagination.total}
                resultsPerPage={10}
                page={pagination.skip / 10 + 1}
                onChange={(value) => update(value.value)}
              />
            </Box>
          </Show.When>
          <Show.Else>
            <FileTable
              data={reveasedDetails}
              onUpdatePage={(value) => {
                update(value);
              }}
              onSelectedRow={onSelectFile}
            />
          </Show.Else>
        </Show>
      </Show.Else>
    </Show>
  );
};

export default Content;
