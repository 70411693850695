import React from "react";
import { Box } from "@mui/material";
import { IStatus } from "../../redux/casesSlice";
import { ITimeLineData } from "../TimeLine/TimeLine";
import { text } from "stream/consumers";
import { getStatusText } from "../../utils";

export const config = (data: IStatus): ITimeLineData[] => {
  if (!data) return [];
  const { qual, proposal, contract, project_material } = data;

  const style = {
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  };
  return [
    {
      id: 1,
      renderer: <Box sx={style}>{getStatusText("Qual", qual)}</Box>,
      status: { present: qual.present, verified: qual.verified },
    },
    {
      id: 2,
      renderer: <Box sx={style}>{getStatusText("Proposal", proposal)}</Box>,
      status: { present: proposal.present, verified: proposal.verified },
    },
    {
      id: 3,
      renderer: <Box sx={style}>{getStatusText("Contract", contract)}</Box>,
      status: { present: contract.present, verified: contract.verified },
    },
    {
      id: 4,
      renderer: (
        <Box sx={style}>{getStatusText("Material", project_material)}</Box>
      ),
      status: {
        present: project_material.present,
        verified: project_material.verified,
      },
    },
  ];
};
