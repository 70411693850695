import { ICaseDocSatus } from "../redux/casesSlice";
import { getTheme } from "../themes";

export const getInitials = (name) => {
  return name
    .split(" ")
    .map((n) => n[0])
    .join("");
};

export const capitalizeFirstLetters = (str: string): string => {
  return str
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
};

export const formatDate = (date: string): string => {
  const splitDate = date.split("-");
  const day = splitDate[2].split("T")[0];
  const month = splitDate[1];
  const year = splitDate[0];
  return `${day}/${month}/${year}`;
};

export const formatTime = (date: string): string => {
  const splitDate = date.split("T")[1].split(":");
  return `${splitDate[0]}:${splitDate[1]}:${splitDate[2].split(".")[0]}`;
};

export const formatDateTime = (date: string): string => {
  return `${formatDate(date)} ${formatTime(date)}`;
};

export const getStatusColour = (params: {
  present: boolean;
  verified: boolean;
}) => {
  if (!params) return;
  const theme = getTheme();
  const { present, verified } = params;
  if (present && verified) return theme.palette.success.main;
  if (present && !verified) return theme.palette.warning.main;
  return theme.palette.grey[400];
};

export const getStatusText = (title: string, data: ICaseDocSatus) => {
  if (!data.present) {
    return `${title} (Required)`;
  }
  if (!data.verified) {
    return `${title} (Pending Verification)`;
  }
  return `${title} Verified`;
};
