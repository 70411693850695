import React, { useState, useEffect } from "react";
import {
  Breadcrumbs,
  IconButton,
  Typography,
  Button,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DefaultTemplate from "@/templates/DefaultTemplate/DefaultTemplate";
import {
  PageHeader,
  SmartSearchLogo,
  PageContent,
  Show
} from "@adl/smart-ui-library";
import { useAppSelector, useAppDispatch } from "@/hooks";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import * as Styled from "./Tasks.styles";
import TaskCard from "./TaskCard";
import { setSelectedTasks } from "@/redux/tasksSlice";
import { setSelectedCase, setSelectedDocument } from "@/redux/casesSlice";
import { useMsal } from "@azure/msal-react";
import NotificationDropdown from "@/components/Notifications/Notifications";
import axiosInstance from "../../axiosConfig";
import LoadingScreen from "../../components/LoadingScreen";

const Tasks = () => {
  const appDispatch = useAppDispatch();
  const [pagination, setPagination] = useState(15);
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [verify, setVerify] = useState(false);
  const [doneTasks, setDoneTasks] = useState([]);

  const navigateToHome = () => {
    appDispatch(setSelectedTasks(false));
    appDispatch(setSelectedCase(null));
    appDispatch(setSelectedDocument(null));
  };
  const { instance } = useMsal();


  const loadTasks = async () => {
    try {
      const result: any = await axiosInstance.get(`tasks?verified=false`);
      setTasks(result.data.results)
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };
  const loadDoneTasks = async () => {
    try {
      const doneResult: any = await axiosInstance.get(`tasks?verified=true`);
      setDoneTasks(doneResult.data.results)
      //setDoneTasks(doneResult.data.results.filter((task) => (task.status === true)))
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };


  const handleVerify = async (payload) => {
    setIsLoading(true)
    try {
      const response: any = await axiosInstance.put(
        `tasks`,
        payload,
      );
      if (response.status === 204) {
        setVerify(true)
        setIsLoading(false)
        return response.data;


      } else {
        return null;
      }
    } catch (error) {
      console.error("Error posting notifications:", error);
    }
  };

  useEffect(() => {
    loadTasks();
    loadDoneTasks();
  }, [pagination, verify]);

  return (
    <DefaultTemplate paddingTop={200}>
      <PageHeader
        logo={
          <SmartSearchLogo variant="default" handleClick={navigateToHome} />
        }
        navigation={
          <>
            <NotificationDropdown />
            <Button onClick={() => appDispatch(setSelectedTasks([]))}>
              Tasks
            </Button>
            <Button onClick={() => instance.logout()}>Logout</Button>
          </>
        }
        subHeader={
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <IconButton onClick={navigateToHome}>
              <HomeOutlinedIcon />
            </IconButton>
            <Typography color="text.primary">Tasks</Typography>
          </Breadcrumbs>
        }
      >
        <Typography variant="h1">Knowledge Vault</Typography>
      </PageHeader>

      <PageContent>
        <Styled.GridLayout>
          <Styled.Cases>
            <Accordion
              defaultExpanded
              sx={
                {
                  //border: `1px solid ${theme.palette.grey[400]}`,
                  //borderRadius: "10px",
                  //boxShadow: theme.shadows[2],
                }
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                To Do
              </AccordionSummary>
              <Show>
                <Show.When isTrue={isLoading}>
                  <LoadingScreen />
                </Show.When>
                <Show.Else>
                  <AccordionDetails>
                    {tasks?.map((task) => (

                      <TaskCard task={task} handleVerify={(p) => handleVerify(p)} />

                    ))}
                  </AccordionDetails>

                </Show.Else>
              </Show>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Done
              </AccordionSummary>
              <AccordionDetails>
                {doneTasks?.map((task) => (

                  <TaskCard task={task} />
                ))}
              </AccordionDetails>
            </Accordion>
          </Styled.Cases>
        </Styled.GridLayout>
      </PageContent>
    </DefaultTemplate>
  );
};

export default Tasks;
