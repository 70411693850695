import { createSlice } from "@reduxjs/toolkit";

interface IUserState {
  isLoading: boolean;
  error: string | null;
  userData: any;
}

export const NAME = "user";
export const GET_USER = `${NAME}/getUser`;

const initialState: IUserState = {
  isLoading: true,
  error: null,
  userData: null,
};

const reducers = {
  getUser: (state: IUserState) => {
    state.isLoading = true;
  },
  getUserSuccess: (state: IUserState, { payload: data }) => {
    state.isLoading = false;
    state.userData = data;
  },
  getUserError: (state: IUserState, { payload: error }) => {
    state.isLoading = false;
    state.error = error;
  },
};

export const userSlice = createSlice({
  name: NAME,
  initialState,
  reducers,
});

export const { getUser, getUserSuccess, getUserError } = userSlice.actions;

export default userSlice.reducer;
