import { Show } from "@adl/smart-ui-library";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import React, { useEffect } from "react";

type Props = {
  text: string;
  onUpdate: (text: string) => void;
};

const EditableTitle = ({ text, onUpdate }: Props) => {
  const [editTitle, setEditTitle] = React.useState(false);
  const [title, setTitle] = React.useState(text);

  useEffect(() => {
    setTitle(text);
  }, [text]);

  return (
    <Show>
      <Show.When isTrue={editTitle}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            variant="outlined"
            sx={{ width: "100%" }}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="text"
              disableElevation
              onClick={() => {
                setTitle(text);
                setEditTitle(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                setEditTitle(false);
                onUpdate(title);
              }}
            >
              Update
            </Button>
          </Box>
        </Box>
      </Show.When>
      <Show.Else>
        <Box sx={{ display: "flex", alignItems: "center", height: "50px" }}>
          <Typography variant="h5" data-test="document-title">
            {title}
          </Typography>
          <IconButton onClick={() => setEditTitle(true)}>
            <EditOutlinedIcon />
          </IconButton>
        </Box>
      </Show.Else>
    </Show>
  );
};

export default EditableTitle;
