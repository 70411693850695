import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Show } from "@adl/smart-ui-library";
import { useAppSelector } from "@/hooks";
import { selectActivities } from "@/redux/casesSlice";
import { formatDate } from "@/utils";
import ActivitiesRendererItem from "./ActivitiesRenderItem";
import TimeLine, { ITimeLineData } from "@/components/TimeLine/TimeLine";

const Activities = () => {
  const activities = useAppSelector(selectActivities);
  const [data, setData] = React.useState<ITimeLineData[]>([]);

  useEffect(() => {
    const parsedActivities = activities?.map((item: any, index: number) => {
      return {
        id: index,
        renderer: (
          <ActivitiesRendererItem
            activity={item.activityType.name}
            name={item.metaData.fileName}
            date={formatDate(item.createdAt.split("T")[0])}
            user={item.user.name}
          />
        ),
        status: { present: true, verified: true },
      };
    });
    setData(parsedActivities);
  }, [activities]);

  if (!data) return null;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Activities</Typography>
      </Box>

      <Show>
        <Show.When isTrue={data?.length > 0}>
          <Box sx={{ padding: "20px 0px" }}>
            <TimeLine direction="vertical" data={data} />
          </Box>
        </Show.When>
        <Show.Else>
          <Typography variant="body1">No Activities Recorded</Typography>
        </Show.Else>
      </Show>
    </Box>
  );
};

export default Activities;
