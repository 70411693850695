import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import { RenderStatus } from "@/components/Table/Table.renderers";

export const columns: GridColDef<(typeof rows)[number]>[] = [
  {
    field: "P_number",
    headerName: "Project Code",
  },
  {
    field: "ShortName",
    headerName: "Project",
    width: 400,
  },

  {
    field: "CaseManagerName",
    headerName: "Case Manager",
    width: 180,
  },

  {
    field: "PartnerInChargeName",
    headerName: "Partner in Charge",
    width: 180,
  },
  {
    field: "FunctionalPractice",
    headerName: "Practice",
    width: 180,
  },
  {
    field: "Statuses",
    headerName: "Status",
    renderCell: (params) => <RenderStatus value={params.value} />,
  },
];
