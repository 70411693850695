import React from "react";
import { Provider } from "react-redux";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { ThemeProvider as ADLThemeProvider } from "@adl/smart-ui-library";
import { MsalProvider } from "@azure/msal-react";
import { store } from "./redux/store";
import Router from "./pages/Router";
import defaultTheme from "./themes/defaultTheme";
import MSALAuthentication from "./components/MSALAuthentication";
import { setTheme } from "./themes";
import { msalInstance } from "./authConfig";

setTheme(defaultTheme);

function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <ADLThemeProvider theme={defaultTheme}>
          <MuiThemeProvider theme={defaultTheme}>
            <MSALAuthentication>
              <Router />
            </MSALAuthentication>
          </MuiThemeProvider>
        </ADLThemeProvider>
      </Provider>
    </MsalProvider>
  );
}

export default App;
