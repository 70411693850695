import React, { useEffect, useReducer } from "react";
import * as Styled from "./DocumentProfileContent.styles";
import { Box, Button, Chip, Typography, useTheme } from "@mui/material";
import { useAppSelector } from "@/hooks";
import { selectSelectedDocument } from "@/redux/casesSlice";
import { formatDate } from "../../utils";
import EditableTitle from "./EditableTitle";
import EditableSummary from "./EditableSummary";

import EditableCategory from "./EditableCategory";
import EditableConfidentiality from "./EditableConfidentiality";
import StatusChip from "../StatusChip/StatusChip";

const savedSummary =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sapien orci, lacinia sed lorem eu, condimentum congue lorem. Phasellus dictum imperdiet lorem, vel gravida neque placerat a. Praesent ornare ligula sed turpis efficitur, pellentesque ullamcorper sapien pretium. Etiam eget erat rutrum, vulputate sapien eu, imperdiet ex. Maecenas mollis ultrices tempus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Maecenas pellentesque ligula sed leo varius, nec egestas est mattis. Mauris vel sapien consequat, consequat dolor id, tincidunt magna. Fusce dapibus gravida gravida. Etiam velit dui, pulvinar ac risus aliquam, venenatis congue dolor. Donec eu ex ac ex lacinia pulvinar. Pellentesque sollicitudin nulla a lacinia consequat. Suspendisse semper neque eu laoreet hendrerit. Cras mattis vel est et pellentesque.";

type Props = {
  dispatch: any;
  state: any;
};

const DocumentProfileContent = ({ dispatch, state }: Props) => {
  const document = useAppSelector(selectSelectedDocument);
  const theme = useTheme();
  const colour = () => {
    switch (document?.fileStatus) {
      case "completed":
        return "#FF8500"; //theme.palette.success.main;
      case "processing":
        return "#FF8500";
      default:
        return theme.palette.error.main;
    }
  };

  const text = () => {
    switch (document?.fileStatus) {
      case "completed":
        return "Pending varification";
      case "processing":
        return "Processing";
      default:
        return "Queued";
    }
  };
  return (
    <>
      <EditableTitle
        text={state.fileName || "Original File Name"}
        onUpdate={(text) => {
          dispatch({ type: "SET_FILE_NAME", payload: text });
        }}
      />
      <Typography variant="h6">Overview</Typography>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <Chip
          sx={{ textTransform: "capitalize" }}
          variant="outlined"
          label={`Created: ${formatDate(document?.createdAt)}`}
        />

        <Chip
          sx={{ textTransform: "capitalize" }}
          variant="outlined"
          label={`Modified: ${formatDate(document?.updatedAt)}`}
        />

        <Chip
          sx={{ textTransform: "capitalize" }}
          variant="outlined"
          label={`Category: ${document?.category}`}
        />

        <StatusChip status={document.fileStatus} />
      </Box>
      <Typography variant="h6">File Category</Typography>
      <EditableCategory
        text={state.category}
        onUpdate={(text) => {
          dispatch({ type: "SET_CATEGORY", payload: text });
        }}
      />
      <Typography variant="h6">Confidentiality Level</Typography>
      <EditableConfidentiality
        text={state.confidentialityLevel}
        onUpdate={(text) => {
          dispatch({ type: "SET_CONFIFENTIALITY_LEVEL", payload: text });
        }}
      />
      <EditableSummary
        text={savedSummary}
        onUpdate={(text) => {
          dispatch({ type: "SET_SUMMARY", payload: text });
        }}
      />
    </>
  );
};

export default DocumentProfileContent;
