import React from "react";
import { useAppSelector } from "@/hooks";
import {
  selectSelectedCases,
  selectSelectedDocument,
} from "@/redux/casesSlice";
import { selectTasks } from "@/redux/tasksSlice";
import { Show } from "@adl/smart-ui-library";
import SelectedCase from "./SelectedCase";
import Dashboard from "./Dashboard";
import SelectedDocument from "./SelectedDocument";
import Tasks from "./Tasks";

const Router = () => {
  const selectedCase = useAppSelector(selectSelectedCases);
  const selectedDocument = useAppSelector(selectSelectedDocument);
  const selectedTasks = useAppSelector(selectTasks);

  return (
    <Show>
      <Show.When isTrue={Boolean(selectedTasks)}>
        <Tasks />
      </Show.When>
      <Show.When isTrue={Boolean(selectedCase) && !selectedDocument}>
        <SelectedCase />
      </Show.When>
      <Show.When isTrue={Boolean(selectedDocument) && Boolean(selectedCase)}>
        <SelectedDocument />
      </Show.When>
      <Show.Else>
        <Dashboard />
      </Show.Else>
    </Show>
  );
};

export default Router;
