import React from "react";
import { PageWrapper } from "@adl/smart-ui-library";

type Props = {
  paddingTop?: number;
  children: React.ReactElement;
};

const DefaultTemplate = ({ children, paddingTop }: Props) => {
  return (
    <PageWrapper debug={import.meta.env.DEV} topPadding={paddingTop || 100}>
      {children}
    </PageWrapper>
  );
};

export default DefaultTemplate;
