import { Box, styled } from "@mui/system";

export const DashboardContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "0px 120px 0px 120px",
  gap: "40px",
  width: "100%",

  [theme.breakpoints.down("xl")]: {
    padding: "0px 120px 0px 120px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "0px 60px 0px 60px",
  },

  [theme.breakpoints.down("sm")]: {
    padding: "0px 40px 0px 40px",
  },

  "& .filepond--credits": {
    display: "none",
  },
}));
