import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "@/axiosConfig";

interface ICase {
  id: number;
  files: any[];
  ADLBusinessUnitCcy: string;
  ADLCareerGrade: string;
  ADLCollaborationIndustry: string;
  ADLContactEmail: string;
  ADLCurrentBUName: string;
  ADLEndDate: string;
  ADLFunctionalPractice: string;
  ADLOwner: string;
  ADLPartnerInCharge: string;
  ADLPartnerinChargeCaseManager: string;
  ADLPnumberlongreference: string;
  ADLPnumbershortreference: string;
  ADLStartDateatTimeofAssignment: string;
  ActivityAssignementCreatedDate: string;
  ActivityAssignementLastModifiedDate: string;
  ActivityAssignementName: string;
  DeliveryEngagementId: string;
  DeliveryEngagementName: string;
  P_number: string;
  ResourceBusinessUnitId: string;
  ResourceId: string;
  ResourceName: string;
  SalesforceUserEmail: string;
  SalesforceUserName: string;
  ShortName: string;
  StageName: string;
  StartDate: string;
}

interface IPageination {
  total: number;
  skip: number;
  top: number;
}

export interface ICaseDocSatus {
  present: boolean;
  verified: boolean;
}

export interface IStatus {
  contract: ICaseDocSatus;
  other: ICaseDocSatus;
  project_material: ICaseDocSatus;
  proposal: ICaseDocSatus;
  qual: ICaseDocSatus;
}

export interface ICasesState {
  cases: ICase[];
  pagination: IPageination;
  isLoading: boolean;
  isError: boolean;
  selectedCase: ICase | null;
  selectedCaseDetails: any;
  selectedCasePagination: IPageination;
  selectedCaseStatus: IStatus | null;
  activites: any;
  selectedDocument: any;
}

export const NAME = "cases";

export const initialState: ICasesState = {
  cases: [],
  pagination: { skip: 0, top: 10, total: 0 },
  isLoading: false,
  isError: false,
  selectedCase: null,
  selectedCaseDetails: null,
  selectedCasePagination: { skip: 0, top: 10, total: 0 },
  selectedCaseStatus: null,
  activites: null,
  selectedDocument: null,
};

export interface ITableFilter {
  field: string;
  operator: string;
  value: string;
}

export type CasesPayload = {
  include: string;
  sort: string;
  top: number;
  skip: number;
  filter?: { items: ITableFilter[] };
};

export const fetchAllCases = createAsyncThunk(
  `${NAME}/fetchAllCases`,
  async (args: CasesPayload, thunkAPI) => {
    try {
      const response = await axiosInstance.post(`/cases`, args);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchCaseDetailsById = createAsyncThunk(
  `${NAME}/fetchCaseDetailsById`,
  async (arg: any, thunkAPI) => {
    try {
      const response = await axiosInstance.get(
        `/cases/details/${arg.deliveryEngagementId}?top=${arg.top}&skip=${arg.skip}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchCaseActivitiesById = createAsyncThunk(
  `${NAME}/fetchCaseActivitiesById`,
  async (arg: any, thunkAPI) => {
    try {
      const response = await axiosInstance.get(
        `/cases/activities/${arg.caseDetailId}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteCaseDocument = createAsyncThunk(
  `${NAME}/deleteCaseDocument`,
  async (arg: any, thunkAPI) => {
    try {
      const response = await axiosInstance.delete(
        `/cases/details/${arg.deliveryEngagementId}/${arg.documentId}`
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const reducers = {
  setSelectedCase: (state: ICasesState, { payload: data }) => {
    state.selectedCase = data;
  },
  setSelectedDocument: (state: ICasesState, { payload: data }) => {
    state.selectedDocument = data;
  },
  setSelectedCaseActivites: (state: ICasesState, { payload: data }) => {
    state.activites = data;
  },
  setSelectedCaseStatuses: (state: ICasesState, { payload: data }) => {
    state.selectedCaseStatus = data;
  },
  setSelectedCasePagination: (state: ICasesState, { payload: data }) => {
    state.selectedCasePagination = data;
  },
};

export const casesSlice = createSlice({
  name: NAME,
  initialState,
  reducers,
  extraReducers: (builder) => {
    builder.addCase(fetchAllCases.pending, (state) => {
      state.selectedCase = null;
      state.selectedCaseDetails = null;
      state.selectedCasePagination = { skip: 0, top: 10, total: 0 };
      state.selectedCaseStatus = null;
      state.isLoading = true;
    });
    builder.addCase(fetchAllCases.fulfilled, (state, action) => {
      state.cases = action.payload.results as ICase[];
      state.pagination = action.payload.pagination as IPageination;
      state.isLoading = false;
    });
    builder.addCase(fetchCaseDetailsById.pending, (state) => {
      state.activites = null;
      state.isLoading = true;
    });
    builder.addCase(fetchCaseDetailsById.fulfilled, (state, action) => {
      state.cases = [];
      state.selectedCaseDetails = action.payload.results.reverse() as ICase[];
      state.selectedCasePagination = action.payload.pagination as IPageination;
      state.selectedCaseStatus = action.payload.statuses as IStatus;
      state.isLoading = false;
    });
    builder.addCase(fetchCaseDetailsById.rejected, (state, action) => {
      state.isError = true;
      state.isLoading = false;
    });
    builder.addCase(fetchCaseActivitiesById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCaseActivitiesById.fulfilled, (state, action) => {
      state.activites = action.payload.results as any[];
      state.isLoading = false;
    });
  },
});

export const selectIsLoading = (state) => state[NAME].isLoading;
export const selectIsError = (state) => state[NAME].isError;
export const selectCases = (state) => state[NAME].cases;
export const selectPagination = (state) => state[NAME].pagination;
export const selectSelectedCases = (state) => state[NAME].selectedCase;
export const selectSelectedCaseDetails = (state) =>
  state[NAME].selectedCaseDetails;
export const selectSelectedCaseStatus = (state) =>
  state[NAME].selectedCaseStatus;
export const selectActivities = (state) => state[NAME].activites;
export const selectedSelectedCasePagination = (state) =>
  state[NAME].selectedCasePagination;
export const selectSelectedDocument = (state) => state[NAME].selectedDocument;

export const {
  setSelectedCase,
  setSelectedDocument,
  setSelectedCaseActivites,
  setSelectedCasePagination,
  setSelectedCaseStatuses,
} = casesSlice.actions;

export default casesSlice.reducer;
