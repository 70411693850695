import { styled } from "@mui/system";

export const Cases = styled("div")(({ theme }) => ({
  gridArea: "main",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  paddingLeft: "120px",
}));

export const ListHeader = styled("div")({
  gridArea: "header",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: "120px",
});

export const CasesDescription = styled("div")({
  width: "100%",
  gridArea: "sidebar",
  "& h2": {
    fontSize: "24px",
    margin: "10px 0 20px 0",
  },
});

export const GridLayout = styled("div")({
  width: "100%",
  display: "grid",
  gridTemplateColumns: "900px 30%",
  columnGap: "40px",
  rowGap: "15px",
  gridTemplateRows: "46px auto",
  gridTemplateAreas: `"main sidebar" "main sidebar"`,
  "@media (max-width: 1450px)": {
    gridTemplateColumns: "100%",
    gridTemplateAreas: `"sidebar" "main"`,
  },
});
