import {
  Typography, Box, useTheme, Button, TextField, InputLabel, Select, FormControl, MenuItem, Checkbox, ListItemText, IconButton, Tooltip,
} from "@mui/material";
import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "@/hooks";
import { selectSelectedCases, setSelectedCase } from "@/redux/casesSlice";
import { Modal, RenderIf, Show } from "@adl/smart-ui-library";
import EditableSummary from "./EditableSummary";
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { styled, alpha } from '@mui/material/styles';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { formatDate } from "../../utils";
import axiosInstance from "../../axiosConfig";


const CasePill = ({
  title,
  value,
  colour,
}: {
  title: string;
  value: string | number;
  colour?: string;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        border: colour
          ? `1px solid ${colour}`
          : `1px solid ${theme.palette.grey[300]}`,
        padding: "4px 10px 3px 10px",
        borderRadius: "5px",
        width: "fit-content",
        display: "flex",
        gap: "5px",
      }}
      onClick={() => null}
    >
      <Typography
        variant="body1"
        sx={{
          color: colour || theme.palette.grey[600],
        }}
      >
        {title}:
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: colour || theme.palette.grey[600],
          fontWeight: "bold",
          textTransform: "capitalize",
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.grey[200],
  [`& .${treeItemClasses.content}`]: {
    background: 'none !important',

    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),
    [`& .${treeItemClasses.label}`]: {
      fontSize: '0.8rem',
      fontWeight: 800,
    },
  },

}));
const savedSummary =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sapien orci, lacinia sed lorem eu, condimentum congue lorem. Phasellus dictum imperdiet lorem, vel gravida neque placerat a. Praesent ornare ligula sed turpis efficitur, pellentesque ullamcorper sapien pretium. Etiam eget erat rutrum, vulputate sapien eu, imperdiet ex. Maecenas mollis ultrices tempus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Maecenas pellentesque ligula sed leo varius, nec egestas est mattis. Mauris vel sapien consequat, consequat dolor id, tincidunt magna. Fusce dapibus gravida gravida. Etiam velit dui, pulvinar ac risus aliquam, venenatis congue dolor. Donec eu ex ac ex lacinia pulvinar. Pellentesque sollicitudin nulla a lacinia consequat. Suspendisse semper neque eu laoreet hendrerit. Cras mattis vel est et pellentesque.";

const TaskCard = ({ task, done, handleVerify }: Props) => {
  const appDispatch = useAppDispatch();
  const data = useAppSelector(selectSelectedCases);
  const theme = useTheme();
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [fileCategory, setFileCategory] = React.useState(task?.document?.category);
  const [confidentialityLevel, setConfidentialityLevel] = React.useState(task?.document?.confidentiality);
  const [summary, setSummary] = React.useState(task?.document?.summary);
  const [editSummary, setEditSummary] = React.useState(false);


  function getStyles() {
    return {
      textAlign: "left",
      borderRadius: "20px",
      border: '1px solid #ccc',
      fontSize: "14px",
    };
  }
  const fileCategories = [
    { name: "Proposal", value: "proposal", id: "1" },
    { name: "Project Material", value: "project_material", id: "2" },
    { name: "Qual", value: "qual", id: "3" },
    { name: "Contract", value: "contract", id: "4" },
    { name: "Other", value: "other", id: "5" },
  ];
  const confidentialityLevels = [
    {
      name: "No quotation allowed",
      value: "private",
      id: "1",
    },
    {
      name: "Quotation allowed",
      value: "public",
      id: "2",
    },
    {
      name: "Sanitised quotation allowed",
      value: "team",
      id: "3",
    },
  ];

  const handleFileCategory = (event) => {
    setFileCategory(event.target.value);
  };

  const handleConfidentiality = (event) => {
    const {
      target: { value },
    } = event;
    setConfidentialityLevel(
      typeof value === "string" ? value?.split(",") : value
    );
  };
  const handleRegenerate = async () => {
    try {
      const result: any = await axiosInstance.get(
        `tasks/33/document/summary`,
      );
      console.log(result)
      setSummary(result.data.summary)

    } catch (error) {
      setSummary(savedSummary)
      console.error("Error fetching notifications:", error);
    }
  }

  const onVerify = () => {
    handleVerify({ taskId: task.taskId, taskType: 'DocumentVerification', document: { summary: summary, category: fileCategory, confidentiality: confidentialityLevel.toString() } });
  };

  useEffect(() => {
    setSummary(task?.document?.summary);
  }, [task]);

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
 }
  console.log(task)
  return (
    <SimpleTreeView sx={{

      background: 'transparent',


    }}>
      <CustomTreeItem itemId="grid" sx={{
        color: theme.palette.primary[700],
        fontWeight: "bold",
        fontSize: "16px",
        padding: '10px 0',
        marginBottom: '20px',
        background: 'none !important',
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: "10px",
        // boxShadow: theme.shadows[1],

      }}
        label={
          <div style={{ position: 'relative' }}>
            <Typography variant="h6"
              sx={{
                color: theme.palette.primary[700],
                fontWeight: "bold",
                fontSize: "16px",
              }}>
              {task?.document?.fileNameOriginal} {task?.verified ? '' : 'needs to be verifed'}

            </Typography>
            <div style={{ position: 'absolute', right: '0', top: '0' }}>
              {task?.verified ? <CheckCircleOutlineIcon style={{ color: 'green' }} /> : <PanoramaFishEyeIcon style={{ color: 'red' }} />}
            </div>
          </div>
        }>

        <Box
          sx={{
            width: "100%",
            padding: "10px",
            margin: '0'
          }}
        >
          <Box sx={{ display: "flex", gap: "10px", flexWrap: 'wrap' }}>
            <CasePill title="Created" value={formatDate(task?.createdAt)} />
            <CasePill title="Created by" value={task?.createdBy} />
            {task?.verified &&
              <>
                {task?.verifiedAt && <CasePill title="Verified" value={formatDate(task?.verifiedAt)} />}
                <CasePill title="Verified by" value={task?.verifiedBy} />
                <CasePill title="Category" value={task?.document.category} />
                <CasePill title="Confidentiality" value={task?.document.confidentiality} />
              </>
            }

          </Box>

          <Box>
            <Box sx={{ display: "flex", alignItems: "center", height: "50px" }}>
              <Typography variant="h6"
                sx={{
                  color: theme.palette.grey[800],
                }}>Summary</Typography>
              <RenderIf isTrue={!task?.verified}>
                <IconButton onClick={handleRegenerate}>
                  <Tooltip title="Regenerate summary" arrow>

                    <RotateLeftIcon />
                  </Tooltip>

                </IconButton>
              </RenderIf>
            </Box>
            <Show>
              <Show.When isTrue={editSummary}>
                <TextField
                  variant="outlined"
                  placeholder="Add summary"
                  onKeyDown={(e) => e.stopPropagation()}
                  sx={{
                    width: "100%",

                    "& .MuiOutlinedInput-root": {
                      padding: "20px",
                      height: "auto",
                      alignItems: "flex-start",
                    },
                    "& .MuiOutlinedInput-inputMultiline": {
                      padding: "20px",
                    },
                  }}
                  onChange={(e) => setSummary(e.target.value)}
                  value={summary}
                  multiline
                />

              </Show.When>
              <Show.Else>
                <div onClick={() => setEditSummary(true)} style={{minHeight: '10px'}}>
                  <Typography variant="body1" data-test="document-summary">
                    {summary ? summary : 'No summary available'}
                  </Typography>
                </div>
              </Show.Else>
            </Show>

          </Box>
          {!task?.verified &&
            <Box
              sx={{
                width: "100%",
                margin: '0',
                display: "flex",
                gap: '10px'
              }}
            >
              <FormControl style={{ width: '100%', marginTop: '20px' }}>
                <InputLabel style={{ background: '#fff' }} id="fileCategory">Document type</InputLabel>
                <Select
                  labelId="fileCategory"
                  id="fileCategory"
                  value={fileCategory}
                  disabled={task?.verified}
                  onChange={handleFileCategory}
                  style={getStyles()}
                >
                  {fileCategories.map((category) => (
                    <MenuItem key={category.value} value={category.value}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{ width: '100%', marginTop: '20px' }}>
                <InputLabel style={{ background: '#fff' }} id="confidentialityLevel">
                  Confidentiality level
                </InputLabel>
                <Select
                  labelId="confidentialityLevel"
                  id="confidentialityLevel"
                  disabled={task?.verified}
                  style={getStyles()}
                  value={confidentialityLevel}
                  onChange={handleConfidentiality}
                //input={<OutlinedInput label="Confidentiality level" />}
                >
                  {confidentialityLevels.map((level) => (
                    <MenuItem key={level.value} value={level.value}>
                     {level.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          }
          <Box
            sx={{
              marginTop: "10px",
              marginBottom: "50px",
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >

          </Box>
          {!task?.verified &&

            <Box
              sx={{
                position: "relative",
                right: "20px",
                bottom: "10px",
                display: "flex",
                gap: "10px",
                float: 'right'
              }}
            >

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >

              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <Button
                  variant="contained"
                  disableElevation
                  disabled={!summary}
                  onClick={onVerify}
                  sx={{ width: "100%" }}
                >
                  Verify
                </Button>

              </Box>

            </Box>
          }


        </Box>



      </CustomTreeItem>
    </SimpleTreeView>
  );
};



export default TaskCard;
