import { Modal, RenderIf, Show } from "@adl/smart-ui-library";
import { Button, Typography } from "@mui/material";
import React from "react";

type Props = {
  open: boolean;
  onClose: () => void;
  onContinue?: () => void;
};

const DiscardChangesModal = ({ open, onClose, onContinue }: Props) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>Discard changes</Modal.Header>
      <Modal.Content>
        <Typography variant="body1">
          You have made changes to this document.
          <br /> Navigating away will discard these changes.
          <br />
          <br /> Are you sure you want to continue?
        </Typography>
      </Modal.Content>
      <Modal.Footer>
        <Button variant="text" onClick={onClose} disableElevation>
          Cancel
        </Button>

        <Button variant="contained" onClick={onContinue} disableElevation>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DiscardChangesModal;
