import { RenderIf, Show } from "@adl/smart-ui-library";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import React, { useEffect } from "react";

type Props = {
  text: string;
  onUpdate: (text: string) => void;
};

const EditableSummary = ({ text, onUpdate }: Props) => {
  const [editSummary, setEditSummary] = React.useState(false);
  const [summary, setSummary] = React.useState(text);

  useEffect(() => {
    setSummary(text);
  }, [text]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", height: "50px", alignItems: "center" }}>
        <Typography variant="h6">Summary</Typography>
        <RenderIf isTrue={!editSummary}>
          <IconButton onClick={() => setEditSummary(true)}>
            <EditOutlinedIcon />
          </IconButton>
        </RenderIf>
      </Box>
      <Show>
        <Show.When isTrue={editSummary}>
          <TextField
            variant="outlined"
            sx={{
              width: "100%",

              "& .MuiOutlinedInput-root": {
                padding: "20px",
                height: "auto",
                alignItems: "flex-start",
              },
              "& .MuiOutlinedInput-inputMultiline": {
                padding: "20px",
              },
            }}
            onChange={(e) => setSummary(e.target.value)}
            value={summary}
            multiline
          />
          <Box
            sx={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="text"
              disableElevation
              onClick={() => {
                setSummary(text);
                setEditSummary(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                setEditSummary(false);
                onUpdate(summary);
              }}
            >
              Update
            </Button>
          </Box>
        </Show.When>
        <Show.Else>
          <Typography variant="body1" data-test="document-summary">
            {summary}
          </Typography>
        </Show.Else>
      </Show>
    </Box>
  );
};

export default EditableSummary;
