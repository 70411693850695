import { createTheme } from "@mui/material";
import FontArray from "./fonts";

const lightColours = {
  PRIMARY_700: "#00299B",
  PRIMARY_600: "#4452A0",
  PRIMARY_500: "#6773B1",
  PRIMARY_400: "#86B6FD",
  PRIMARY_300: "#CACFE3",
  PRIMARY_200: "#E1E4EF",
  PRIMARY_100: "#EFF3FC",
  SUCCESS: "#1C8834",
  ERROR: "#C61010",
  ERROR_LIGHT: "#EEA5A5",
  CTA: "#C34EF9",
  CTA_LIGHT: "#E56FFB",
  BLACK: "#000000",
  WHITE: "#FFFFFF",
  GREY_900: "#0B0D26",
  GREY_800: "#292636",
  GREY_700: "#51525F",
  GREY_600: "#71717C",
  GREY_500: "#BDBDC1",
  GREY_400: "#DDDCDF",
  GREY_300: "#EDEDED",
  GREY_200: "#FAFAFA",
  GREY_100: "#FFFFFF",
};

const BOX_SHADOW = "0 0.5rem 0.75rem rgba(0, 0, 0, 0.1)";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    filterTag: true;
    tag: true;
  }
}

declare module "@mui/material/styles" {
  interface Theme {
    chatLoader?: {
      color: string;
    };
    chipColors: {
      SaleMaterial: string;
      Project: string;
      Service: string;
      Publication: string;
      Research: string;
    };
  }
  interface PaletteColor {
    100?: string;
    200?: string;
    300?: string;
    400?: string;
    500?: string;
    600?: string;
    700?: string;
    800?: string;
    900?: string;
  }
  interface ThemeOptions {
    chatLoader?: {
      color: string;
    };
    chipColors: {
      SaleMaterial: string;
      Project: string;
      Service: string;
      Publication: string;
      Research: string;
    };
  }
  interface SimplePaletteColorOptions {
    100?: string;
    200?: string;
    300?: string;
    400?: string;
    500?: string;
    600?: string;
    700?: string;
    800?: string;
    900?: string;
  }
}

const defaultTheme = createTheme({
  chatLoader: {
    color: lightColours.GREY_500,
  },
  chipColors: {
    SaleMaterial: "#baf8d4",
    Project: "#a2c7fb",
    Service: "#e7afff",
    Publication: "#99f7ff",
    Research: "#9e99b2",
  },
  palette: {
    mode: "light",
    primary: {
      main: lightColours.PRIMARY_700,
      light: lightColours.PRIMARY_600,
      dark: "#CACFE3",
      contrastText: lightColours.GREY_100,
      100: lightColours.PRIMARY_100,
      200: lightColours.PRIMARY_200,
      300: lightColours.PRIMARY_300,
      400: lightColours.PRIMARY_400,
      500: lightColours.PRIMARY_500,
      600: lightColours.PRIMARY_600,
      700: lightColours.PRIMARY_700,
    },
    secondary: {
      main: lightColours.GREY_100,
      light: lightColours.PRIMARY_100,
      dark: "#CACFE3",
      contrastText: lightColours.GREY_900,
    },
    success: {
      main: lightColours.SUCCESS,
      contrastText: lightColours.GREY_100,
    },
    error: {
      main: lightColours.ERROR,
      contrastText: lightColours.GREY_100,
    },
    grey: {
      100: lightColours.GREY_100,
      200: lightColours.GREY_200,
      300: lightColours.GREY_300,
      400: lightColours.GREY_400,
      500: lightColours.GREY_500,
      600: lightColours.GREY_600,
      700: lightColours.GREY_700,
      800: lightColours.GREY_800,
      900: lightColours.GREY_900,
    },
    divider: lightColours.GREY_300,
  },
  typography: {
    fontFamily: "CASaygonText, sans-serif",
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          dropShadow: "none",
          padding: "0px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: "22px",
          fontWeight: "bold",
          margin: 0,
        },
        h3: {
          fontSize: "14px",
          fontWeight: "bold",
          margin: 0,
        },
        h4: {
          fontSize: "24px",
          fontWeight: "bold",
          margin: 0,
        },
        h6: {
          fontSize: "16px",
          fontWeight: "bold",
          margin: 0,
        },
        body1: {
          fontSize: "14px",
          fontWeight: "normal",
          color: lightColours.GREY_700,
          margin: 0,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "@global": {
          "@font-face": [...FontArray],
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          padding: "0px 16px 0px 12px",
          borderRadius: "50px",
          borderColor: "transparent",
          backgroundColor: lightColours.GREY_100,
          width: "100%",
          fontSize: "14px",

          "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 5,
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "filterTag" },
          style: {
            backgroundColor: lightColours.GREY_300,
            color: lightColours.GREY_700,
            border: `1px solid ${lightColours.GREY_500}`,
            borderRadius: 5,
            padding: "2px 8px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: lightColours.GREY_400,
            },
          },
        },
        {
          props: { variant: "tag" },
          style: {
            backgroundColor: lightColours.GREY_300,
            color: lightColours.GREY_700,
            border: `1px solid ${lightColours.GREY_500}`,
            borderRadius: 5,
            padding: "2px 8px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: lightColours.GREY_400,
            },
          },
        },
      ],
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: "none",
          borderRadius: 20,
          width: "fit-content",
          padding: "8px 24px 8px 20px",
          ...(ownerState.variant === "contained" && {
            backgroundColor: lightColours.PRIMARY_700,
            "&:hover": {
              backgroundColor: lightColours.PRIMARY_600,
            },
            "&.Mui-disabled": {
              cursor: "not-allowed",
              backgroundColor: lightColours.PRIMARY_300,
            },
          }),
          ...(ownerState.variant === "outlined" && {
            backgroundColor: lightColours.GREY_100,
            borderColor: lightColours.GREY_300,
            "&:hover": {
              backgroundColor: lightColours.GREY_300,
              borderColor: lightColours.GREY_300,
            },
            "&.Mui-disabled": {
              cursor: "not-allowed",
              backgroundColor: lightColours.PRIMARY_300,
            },
          }),
        }),
        contained: {
          color: lightColours.GREY_100,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 16,
          backgroundColor: lightColours.GREY_200,
          boxShadow: BOX_SHADOW,
          display: "flex",
          minWidth: "400px",
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            borderRadius: 10,
            fontSize: "14px",
            borderColor: lightColours.PRIMARY_700,
            backgroundColor: lightColours.GREY_100,
            padding: "0px",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: lightColours.PRIMARY_700,
            borderWidth: 2,
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: lightColours.PRIMARY_700,
              borderWidth: 2,
            },
            "&:hover fieldset": {
              borderColor: lightColours.PRIMARY_700,
              borderWidth: 2,
            },
            "&.Mui-focused fieldset": {
              borderColor: lightColours.PRIMARY_700,
              borderWidth: 2,
            },
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          padding: 0,
          border: "none",
          dropShadow: "none",
          boxShadow: "none",
        },
      },
    },
  },
});

export default defaultTheme;
