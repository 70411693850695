import React, { useEffect } from "react";
import axios from "axios";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import { Modal, RenderIf } from "@adl/smart-ui-library";
import axiosInstance from "../../axiosConfig";
import { setUpdatedTasks } from "@/redux/tasksSlice";
import { useAppDispatch } from "@/hooks";

type ErrorState = React.ReactElement | null;

type Props = {
  id: string;
  email: string;
  uploadComplete: () => void;
};

const FileUploader = ({ id, email, uploadComplete }: Props) => {
  const [files, setFiles] = React.useState<any[]>([]);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [error, setError] = React.useState<ErrorState>(null);
  //const [url, setUrl] = React.useState<string>("");
  const [fileMetadata, setFileMetadata] = React.useState<any[]>([]);
  const [currentFileIndex, setCurrentFileIndex] = React.useState<number>(0);
  const filePondRef = React.useRef<FilePond | null>(null);
  const [progress, setProgress] = React.useState<number>(0);
  const [isComplete, setIsComplete] = React.useState<boolean>(false);
  const appDispatch = useAppDispatch();

  const isError = error !== null;

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionStorage?.getItem("msalAccessToken")}`,
    Accept: "application/json",
  };
  useEffect(() => {
    if (files.length > 0 && currentFileIndex < files.length) {
      setModalOpen(true);
    }
  }, [files, currentFileIndex]);

  /* useEffect(() => {
     if (url !== "" && currentFileIndex < files.length) {
       const file = files[currentFileIndex].file;
       if (filePondRef.current && file) {
         //handleUpload(file);
       }
     }
   }, [url]);*/

  const handleFileAdd = (error, file: any) => {
    if (!error) {
      setFiles((prevFiles) => [...prevFiles, file]);
    }
  };

  const handleFilesLoaded = () => {
    if (files.length > 0) {
      setModalOpen(true);
    }
  };

  const handleUpdateFiles = (fileItems) => {
    const newFileCount = fileItems.length;
    setFiles(fileItems);
  };

  const onCloseMetaModal = async (fileMetadataForFile) => {
    const { fileCategory, confidentialityLevel } = fileMetadataForFile;
    const file = files[currentFileIndex].file;

    const fileExtension = file.name.split(".").pop();

    try {
      const result: any = await axiosInstance.get(
        `documents/upload-urls?deliveryEngagementId=${id}&fileName=${file.name}&category=${fileCategory}&confidentiality=${confidentialityLevel}`,
        { headers }
      );

      if (result.statusText === "OK") {
        //setUrl(result.data.results[0]);
        const file = files[currentFileIndex].file;
        if (filePondRef.current && file) {
          handleUpload(file, result.data.results[0]);
        }
      }
    } catch (error) {
      console.error("Error fetching file upload URL:", error);
      setError(<>Ooops something went wrong. Please try again.</>)
    }

    // Move to the next file
    //setCurrentFileIndex((prevIndex) => prevIndex + 1);
    if (currentFileIndex + 1 >= files.length) {
      setModalOpen(false);
    }
  };

  const handleCancel = () => {
    setModalOpen(false);
    setFiles([]);
    if (filePondRef.current) {
      filePondRef.current.removeFiles();
    }
  };

  const handleUpload = async (file, url) => {
    console.log("Uploading file", file);
    try {
      const blob = new Blob([file], { type: file.type });

      const response: any = await axios.put(url, blob, {
        headers: {
          "Content-Type": "application/octet-stream",
          "x-ms-blob-type": `BlockBlob`,
        },
      });

      console.log(response);

      if (response.status !== 201) {
        throw new Error("Upload failed");
      }

      // Handle success, maybe update the UI to show a success message
      console.log("File uploaded successfully");
      const previousFiles = [...files];
      previousFiles.shift();
      setFiles(previousFiles);
      setIsComplete(true);
      uploadComplete();
      appDispatch(setUpdatedTasks(true));

    } catch (error) {
      // Handle error, e.g., display error message
      console.error("Error uploading file:", error);
      setError(<>Ooops something went wrong. Please try again</>)
    }
  };

  return (
    <>
      <FilePond
        ref={filePondRef}
        files={files}
        onupdatefiles={handleUpdateFiles}
        onaddfile={handleFileAdd}
        allowProcess={false}
        allowMultiple={true}
        maxFiles={10}
        instantUpload={false}
        name="files"
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
      {isComplete && <p>Upload Complete!</p>}
      <RenderIf isTrue={isComplete}>
        <FileUploadStatusModal
          onClose={() => {
            setIsComplete(false);
          }}
        >
          File uploaded successfully.
        </FileUploadStatusModal>
      </RenderIf>
      <RenderIf isTrue={isError}>
        <FileUploadStatusModal
          onClose={() => {
            setError(null);
            setFiles([]);
          }}
        >
          <span style={{ color: 'red' }}>{error}</span>
        </FileUploadStatusModal>
      </RenderIf>
      <RenderIf isTrue={modalOpen}>
        <MetaModal
          onClose={(fileMetadataForFile) =>
            onCloseMetaModal(fileMetadataForFile)
          }
          onCancel={handleCancel}
          data={files[currentFileIndex]}
          index={currentFileIndex}
          total={files.length}
        />
      </RenderIf>
    </>
  );
};

const MetaModal = ({ onClose, onCancel, data, index, total }) => {
  const [text, setText] = React.useState("");
  const [fileCategory, setFileCategory] = React.useState("");
  const [confidentialityLevel, setConfidentialityLevel] = React.useState("");
  console.log("data", data, index);
  const fileCategories = [
    { name: "Proposal", value: "proposal", id: "1" },
    { name: "Project Material", value: "project_material", id: "2" },
    { name: "Qual", value: "qual", id: "3" },
    { name: "Contract", value: "contract", id: "4" },
    { name: "Other", value: "other", id: "5" },
  ];
  const confidentialityLevels = [
    {
      name: "No quotation allowed",
      value: "private",
      id: "1",
    },
    {
      name: "Quotation allowed",
      value: "public",
      id: "2",
    },
    {
      name: "Sanitised quotation allowed",
      value: "team",
      id: "3",
    },
  ];


  const handleFileCategory = (event) => {
    setFileCategory(event.target.value);
  };

  const handleConfidentiality = (event) => {
    const {
      target: { value },
    } = event;
    setConfidentialityLevel(
      typeof value === "string" ? value.split(",") : value
    );
  };
  useEffect(() => {
    setFileCategory("proposal");
    setConfidentialityLevel('private');
  }, [data]);

  function getStyles() {
    return {
      textAlign: "left",
      borderRadius: "20px",
      padding: "1px 3px",
      fontSize: "14px",
    };
  }
  return (
    <Modal open={true} onClose={onCancel} name="delete-history-confirmation">
      <Modal.Header>Add Meta Data</Modal.Header>
      <Modal.Content>
        <Typography variant="body1" sx={{ width: "100%", textAlign: "left" }}>
          File {index + 1} of {total}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <TextField
            label="File Name"
            value={data?.file ? data?.file.name : ""}
            focused
            onChange={(event) => setText(event.target.value)}
          />
          <FormControl>
            <InputLabel id="fileCategory">File category</InputLabel>
            <Select
              labelId="fileCategory"
              id="fileCategory"
              value={fileCategory}
              onChange={handleFileCategory}
              style={getStyles()}
            >
              {fileCategories.map((category) => (
                <MenuItem key={category.value} value={category.value}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="confidentialityLevel">
              Confidentiality level
            </InputLabel>
            <Select
              labelId="confidentialityLevel"
              id="confidentialityLevel"
              style={getStyles()}
              value={confidentialityLevel}
              onChange={handleConfidentiality}
              input={<OutlinedInput label="Confidentiality level" />}
            >
              {confidentialityLevels.map((level) => (
                <MenuItem key={level.value} value={level.value}>
                 {level.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Modal.Content>
      <Modal.Footer>
        <Button
          variant="outlined"
          disableElevation
          onClick={onCancel}
          sx={{ width: "100%" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disableElevation
          onClick={() => onClose({ fileCategory, confidentialityLevel })}
          sx={{ width: "100%" }}
        >
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const FileUploadStatusModal = ({ onClose, children }) => {
  return (
    <Modal open={true} onClose={() => null} name="delete-history-confirmation">
      <Modal.Content>{children}</Modal.Content>
      <Modal.Footer>
        <Button variant="contained" disableElevation onClick={onClose}>
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FileUploader;
