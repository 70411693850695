import React, { useState, useEffect } from 'react';
import { IconButton, Badge, Menu, MenuItem, ListItemText, Divider } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import axiosInstance from "../../axiosConfig";
import { useAppSelector, useAppDispatch } from "@/hooks";
import { setSelectedTasks, updateTasks } from "@/redux/tasksSlice";

const NotificationDropdown = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notifications, setNotifications] = useState([]);
  const [pagination, setPagination] = useState(15);
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [unreadCount, setUnreadCount] = useState(0);
  const appDispatch = useAppDispatch();
  const updatedTasks = useAppSelector(updateTasks);

  const navigateToTasks = () => {
    appDispatch(setSelectedTasks(true));
  };
  const handleRead = async () => {
    try {
      const response: any = await axiosInstance.post(
        `notifications/mark-as-read`,
        {
          notificationIds: notifications
         .filter((notification) => (
            notification.isRead === false
          ))
          .map((notification) => (
            notification.notificationId
          ))
        },
      );
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error posting notifications:", error);
    }
  };

  const handleOpenMenu = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if(unreadCount > 0) {
      handleRead()
    }

  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    if(unreadCount > 0) {
      handleRead()
    }
  };


  const loadNotifications = async () => {
    try {
      const result: any = await axiosInstance.get(
        `notifications?top=${pagination}&skip=0`,
      );
      setNotifications(result.data.results)
      setUnreadCount(result.data.pagination.unread)
      setTotalNotifications(result.data.pagination.total)
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const viewMore = () => {
    setPagination(pagination + 5);
    if(unreadCount > 0) {
      handleRead()
    }
    loadNotifications();
  }

  useEffect(() => {
    loadNotifications();
  }, [pagination, updatedTasks]);

  return (
    <>
      <IconButton color="inherit" onClick={handleOpenMenu}>
        <Badge badgeContent={unreadCount} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            maxHeight: 300,
            width: '390px',
          },
        }}
      >
        {notifications?.length === 0 && (
          <MenuItem onClick={navigateToTasks}>
            <ListItemText primary="No new notifications" />
          </MenuItem>
        )}
        {notifications?.map((notification) => (
          <div key={notification.notificationId} style={{ fontWeight: notification.isRead ? '500' : '800' }}>
            <div style={{ display: 'flex' }}>
              <div style={{height: 'auto', width: '3%', position: 'relative'}}>
                <span style={{display: 'block',  position: 'absolute', top: '12px', left: '10px', height: '10px', width: '10px', borderRadius: '50%', background: !notification.isRead ? 'red' : 'grey' }}></span>
              </div>
              <MenuItem onClick={() => navigateToTasks()}>
                <ListItemText
                  primary={notification.body.event_description || notification.body.event_type}
                  primaryTypographyProps={{
                    fontWeight: notification.isRead ? '500' : '600',
                    marginLeft: '5px',
                    whiteSpace: 'normal',
                    height: '30px'
                  }}
                //secondary={notification.read ? "Read" : "Unread"}
                />
              </MenuItem>
            </div>
            <Divider />
          </div>
        ))}
        {totalNotifications > pagination &&
          <span onClick={viewMore} style={{ cursor: 'pointer', fontWeight: '600', color: 'rgb(0, 41, 155)', fontSize: '12px', padding: '15px 150px', textDecoration: 'none' }}>View more</span>
        }
      </Menu>

    </>
  );
};

export default NotificationDropdown;
