import { Box, styled } from "@mui/system";

export const Wrapper = styled(Box)(({ theme }) => ({
  padding: "0px 120px 0px 120px",
  width: "100%",

  maxWidth: theme.breakpoints.values.xl,
  [theme.breakpoints.down("xl")]: {
    padding: "0px 120px 0px 120px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "0px 60px 0px 60px",
  },

  [theme.breakpoints.down("sm")]: {
    padding: "0px 40px 0px 40px",
  },
}));

export const InputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "20px",
  maxWidth: "800px",
}));

export const ResetSaveButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  gap: "20px",
  justifyContent: "flex-end",
  marginTop: "20px",
  maxWidth: "800px",
}));
