import React from "react";
import { Typography } from "@mui/material";
import * as Styled from "./ActivitiesRendererItem.styles";

type Props = {
  activity: string;
  name: string;
  date: string;
  user: string;
};

const ActivitiesRendererItem = ({ activity, name, date, user }: Props) => {
  return (
    <Styled.ActivitiesRenderItemWrapper>
      <Styled.ActivitiesRenderItemHeader>
        <Typography variant="h3">{activity}</Typography>
        <Typography variant="h3">"{name}"</Typography>
      </Styled.ActivitiesRenderItemHeader>
      <Styled.ActivitiesRenderItemBody>
        <Typography variant="body1">{date}</Typography>
        <Typography variant="body1">{user}</Typography>
      </Styled.ActivitiesRenderItemBody>
    </Styled.ActivitiesRenderItemWrapper>
  );
};

export default ActivitiesRendererItem;
