import { Box, styled } from "@mui/system";

export const ActivitiesRenderItemWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
}));

export const ActivitiesRenderItemHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: "10px",

  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "row",
  },
}));

export const ActivitiesRenderItemBody = styled(Box)(({ theme }) => ({
  height: "10px",
  display: "flex",
  gap: "10px",

  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "row",
  },
}));
