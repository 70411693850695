import { Modal, RenderIf, Show } from "@adl/smart-ui-library";
import { Button, Typography } from "@mui/material";
import React from "react";

type Props = {
  open: boolean;
  onClose: () => void;
  onSave?: () => void;
};

const SaveChangesModal = ({ open, onClose, onSave }: Props) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>Save updates</Modal.Header>
      <Modal.Content>
        <Typography variant="body1">
          You have made changes to this document. <br />
          <br />
          Do you want to save it?
        </Typography>
      </Modal.Content>
      <Modal.Footer>
        <Button variant="text" onClick={onClose} disableElevation>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSave} disableElevation>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SaveChangesModal;
