import {
  Box,
  Chip,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { formatDate } from "../../utils";
import { RenderIf } from "@adl/smart-ui-library";
import StatusChip from "../StatusChip/StatusChip";
type Props = {
  data: any;
  onSelect: (data: any) => void;
  onDeleteFile: (data: any) => void;
};

const FileCard = ({ data, onSelect, onDeleteFile }: Props) => {
  const theme = useTheme();
  const date = new Date(data.createdAt).toISOString().split("T")[0];
  const complete = data.fileStatus === "completed";

  return (
    <Box
      sx={{
        position: "relative",
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: "10px",
        boxShadow: theme.shadows[1],
        padding: "20px",
        cursor: "pointer",
      }}
    >
      <Box onClick={() => onSelect(data)}>
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.primary[700],
            fontWeight: "bold",
            fontSize: "16px",
          }}
        >
          {data.fileNameOriginal || "Original File Name"}
        </Typography>
        <Box
          sx={{
            marginTop: "10px",
            marginBottom: "10px",
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <StatusChip status={data.fileStatus} />
          <Chip
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            label={`Category: ${data.category}`}
          />
          <Chip
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            label={`Created: ${formatDate(date)}`}
          />
        </Box>
      </Box>
      <Box sx={{ position: "absolute", right: "10px", top: "10px" }}>
        <RenderIf isTrue={complete}>
          <Tooltip title="Preview" arrow>
            <IconButton size="small" onClick={() => null}>
              <PreviewOutlinedIcon />
            </IconButton>
          </Tooltip>
        </RenderIf>
        <RenderIf isTrue={complete}>
          <Tooltip title="Download" arrow>
            <IconButton size="small" onClick={() => null}>
              <FileDownloadOutlinedIcon />
            </IconButton>
          </Tooltip>
        </RenderIf>
        <Tooltip title="Delete File" arrow>
          <IconButton size="small" onClick={() => onDeleteFile(data.documentId)}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default FileCard;
